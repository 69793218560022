import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
  TickerMain,
  Title,
  TickerOuter,
  TickerInner,
  TickerContent,
} from './index.style';
import AnimateSplitText from 'components/_animation/AnimateSplitText';
import AnimateFadeIn from 'components/_animation/AnimateFadeIn';

const Ticker = ({ items, title, borderTop = false, borderBottom = false }) => {
  const contentRefs = useRef([]);

  const pauseAnimation = () => {
    for (const ref of contentRefs.current) {
      ref.style.animationPlayState = 'paused';
    }
  };

  const playAnimation = () => {
    for (const ref of contentRefs.current) {
      ref.style.animationPlayState = 'running';
    }
  };

  return (
    <TickerMain $borderTop={borderTop} $borderBottom={borderBottom}>
      <Title>
        <AnimateSplitText type="lines" heading="true">
          {title}
        </AnimateSplitText>
      </Title>

      <AnimateFadeIn delay={0.3}>
        <TickerOuter>
          <TickerInner
            onMouseEnter={pauseAnimation}
            onMouseLeave={playAnimation}
          >
            <TickerContent ref={(ref) => (contentRefs.current[0] = ref)}>
              {React.Children.toArray(
                items.map((item) => (
                  <a href={item.description} target="_blank" rel="noreferrer">
                    <img src={item.file.url} alt={item.title} />
                  </a>
                )),
              )}
            </TickerContent>

            <TickerContent
              ref={(ref) => (contentRefs.current[1] = ref)}
              aria-hidden
            >
              {React.Children.toArray(
                items.map((item) => (
                  <a href={item.description} target="_blank" rel="noreferrer">
                    <img src={item.file.url} alt={item.title} />
                  </a>
                )),
              )}
            </TickerContent>

            <TickerContent
              ref={(ref) => (contentRefs.current[2] = ref)}
              aria-hidden
            >
              {React.Children.toArray(
                items.map((item) => (
                  <a href={item.description} target="_blank" rel="noreferrer">
                    <img src={item.file.url} alt={item.title} />
                  </a>
                )),
              )}
            </TickerContent>
          </TickerInner>
        </TickerOuter>
      </AnimateFadeIn>
    </TickerMain>
  );
};

Ticker.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
};

export default Ticker;
