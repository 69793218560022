import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/SEO';
import ScrollSection from 'components/ScrollSection';
import Footer from 'components/Footer';
import LatestBlogPosts from 'components/LatestBlogPosts';
import HomeScience from 'components/HomeScience';
import Ticker from 'components/Ticker';
import HomeHero from 'components/HomeHero';
import HomeProduct from 'components/HomeProduct';
import SolutionsFeatures from 'components/SolutionsFeatures';
import HomeHeroTwo from 'components/HomeHeroTwo';

const IndexPage = ({ data }) => {
  const {
    heroTitle,
    heroText,
    heroImages,
    heroVideo,
    heroVideoPoster,
  } = data.allContentfulPageHome.nodes[0];

  const {
    metaDescription,
    heroAnimatedTitle,
    heroSubtitle,
    heroButtons,
    heroAnimation,
    productsKicker,
    productsTitle,
    productsText,
    expressionMeasurementLabel,
    expressionMeasurementSolutions,
    customModelsLabel,
    customModelsSolutions,
    featuresKickerText,
    featuresTitle,
    featuresText,
    features,
    publicationsTitle,
    publicationsTitle2,
    publications,
    scienceKickerText,
    scienceTitle,
    scienceButton,
    scienceCarouselItems,
    aboutText,
    aboutTitle,
    aboutButtons,
    blogKickerText,
    blogTitle,
    blogDescription,
    blogButton,
  } = data.allContentfulHomeV2BDec8.nodes[0];

  const latestBlogPosts = data.allContentfulPagePost.nodes;

  return (
    <>
      <SEO title="Home" description={metaDescription.metaDescription} noIndex />

      <ScrollSection>
        <HomeHero
          titleData={heroAnimatedTitle}
          subtitle={heroSubtitle}
          buttons={heroButtons}
          animationMedia={heroAnimation}
        />
      </ScrollSection>

      <ScrollSection>
        <HomeProduct
          title={productsTitle}
          text={productsText}
          kickerText={productsKicker}
          rowOneLabel={expressionMeasurementLabel}
          rowOneItems={expressionMeasurementSolutions}
          rowTwoLabel={customModelsLabel}
          rowTwoItems={customModelsSolutions}
        />
      </ScrollSection>

      <ScrollSection>
        <SolutionsFeatures
          kicker={featuresKickerText}
          title={featuresTitle}
          text={featuresText}
          features={features}
        />
      </ScrollSection>

      <ScrollSection>
        <HomeScience
          kickerText={scienceKickerText}
          title={scienceTitle}
          button={scienceButton}
          carouselItems={scienceCarouselItems}
        />
      </ScrollSection>

      <ScrollSection>
        <Ticker
          items={publications}
          title={publicationsTitle2}
          borderBottom
          borderTop
        />
      </ScrollSection>

      <ScrollSection>
        <HomeHeroTwo
          title={aboutTitle}
          text={aboutText}
          images={heroImages}
          buttons={aboutButtons}
          video={heroVideo}
          poster={heroVideoPoster.file.url}
        />
      </ScrollSection>

      <ScrollSection>
        <LatestBlogPosts
          title={blogTitle}
          kickerText={blogKickerText}
          description={blogDescription}
          posts={latestBlogPosts}
          button={blogButton}
        />
      </ScrollSection>

      <Footer />
    </>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query HomePageV2BQuery {
    allContentfulHomeV2BDec8 {
      nodes {
        metaDescription {
          metaDescription
        }
        heroAnimatedTitle {
          part1
          part2
          animation {
            title
            media {
              title
              file {
                url
                contentType
              }
              fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              fixed(quality: 100, width: 480) {
                src
                ...GatsbyContentfulFixed_noBase64
              }
            }
          }
        }
        heroSubtitle
        heroButtons {
          ...ButtonQuery
        }
        heroAnimation {
          title
          media {
            title
            file {
              url
              contentType
            }
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        publicationsTitle
        publicationsTitle2
        publications {
          file {
            url
          }
          title
          description
        }
        productsKicker
        productsTitle
        productsText
        expressionMeasurementLabel
        expressionMeasurementSolutions {
          ...SolutionPreviewQuery
        }
        customModelsLabel
        customModelsSolutions {
          ...SolutionPreviewQuery
        }
        featuresKickerText
        featuresTitle
        features {
          ...FeatureQuery
        }
        scienceKickerText
        scienceTitle
        scienceButton {
          ...ButtonQuery
        }
        scienceCarouselItems {
          image {
            fluid(maxWidth: 931) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          text
        }
        aboutText
        aboutTitle
        aboutButtons {
          ...ButtonQuery
        }
        blogKickerText
        blogTitle
        blogDescription
        blogButton {
          ...ButtonQuery
        }
      }
    }

    allContentfulPageHome {
      nodes {
        metaDescription {
          metaDescription
        }
        heroTitle
        heroText
        heroImages {
          file {
            details {
              image {
                width
                height
              }
            }
          }
          fixed(quality: 90, width: 300, height: 413, cropFocus: CENTER) {
            src
            ...GatsbyContentfulFixed_noBase64
          }
        }
        heroButtons {
          ...ButtonQuery
        }
        heroVideo {
          file {
            url
            contentType
          }
        }
        heroVideoPoster {
          file {
            url
          }
        }
      }
    }

    allContentfulPagePost(
      filter: { heroTitle: { ne: "dummy article" } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      nodes {
        slug
        heroTitle
        heroCopy {
          raw
        }
        typeOfPost
        heroImage {
          title
          description
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
