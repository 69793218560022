import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextCTA } from './TextStyles';
import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';

const BlogTag = ({ type }) => {
  const [tagColor, setTagColor] = useState('');

  useEffect(() => {
    switch (type) {
      case 'Article':
        setTagColor(colors.accentBlue);
        break;

      case 'Podcast':
        setTagColor(colors.accentPink);
        break;

      case 'Science':
        setTagColor(colors.pastelGreen400);
        break;

      case 'Product Updates':
        setTagColor(colors.accentOrange);
        break;
    }
  }, [type]);

  return (
    <Wrapper color={tagColor} id="blog-tag">
      <TextCTA>{type}</TextCTA>
    </Wrapper>
  );
};

export default BlogTag;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.color};
  width: fit-content;
  flex-shrink: 0;

  height: 32px;
  border-radius: 4px;
  padding: 0 10px;

  ${mq.tabletP} {
    height: 34px;
  }

  ${mq.contained} {
    border-radius: 0.21vw;
    height: 1.77vw;
    padding: 0 0.52vw;
  }
`;
