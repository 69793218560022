import React, { useRef, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import Particles from 'components/Particles';
import ContentfulButton from 'components/ContentfulButton';
import MainLayout from 'components/_layouts/MainLayout';
import { HeroTitle, TextBodyLarge } from 'components/TextStyles';
import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';
import { gsap } from 'gsap';
import HomeHeroVideo from '../HomeHeroVideo';
import PropTypes from 'prop-types';
import { useCycle } from 'utils/useCycle';

import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

const easing = CustomEase.create(
  'custom',
  'M0,0 C0.266,0.412 0.018,0.758 0.406,0.912 0.458,0.932 0.78,1 1,1 ',
);

const FRAME_INTERVAL = 7000;
const FRAME_TRANSITION = 2000;

const HomeHeroProps = {
  titleData: PropTypes.shape({
    part1: PropTypes.string.isRequired,
    part2: PropTypes.string.isRequired,
    animation: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        media: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            file: PropTypes.shape({
              url: PropTypes.string.isRequired,
              contentType: PropTypes.string.isRequired,
            }).isRequired,
            fluid: PropTypes.shape({
              src: PropTypes.string.isRequired,
            }).isRequired,
            fixed: PropTypes.shape({
              src: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    ).isRequired,
  }),
  subtitle: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  animationMedia: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      file: PropTypes.shape({
        url: PropTypes.string.isRequired,
        contentType: PropTypes.string.isRequired,
      }).isRequired,
      fluid: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
      fixed: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ),
};

/**
 * HomeHero
 *
 * @type {React.FC<import('prop-types').InferProps<HomeHeroProps>>}
 * @returns {React.ReactElement}
 */
const HomeHero = ({ titleData, subtitle, buttons, animationMedia }) => {
  /** @type {React.MutableRefObject<Array<HTMLDivElement>>} */
  const wordsRef = useRef([]);

  /** @type {React.MutableRefObject<Array<HTMLDivElement>>} */
  const imagesRef = useRef([]);

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const graphsContainerRef = useRef(null);

  /** @type {React.MutableRefObject<Array<HTMLDivElement>>} */
  const graphsRef = useRef([]);

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const codeSnippetContainerRef = useRef(null);

  /** @type {React.MutableRefObject<Array<HTMLDivElement>>} */
  const codeSnippetRef = useRef([]);

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const audioContainerRef = useRef(null);

  /** @type {React.MutableRefObject<Array<HTMLDivElement>>} */
  const audioRef = useRef([]);

  /**
   * @param {string} activeFrame
   */
  const animationTick = (activeFrame) => {
    wordsRef.current.forEach((element, index) => {
      gsap.killTweensOf(element);

      const key = animationMedia[index].title.toLowerCase();
      if (activeFrame === key) {
        // Active state
        gsap.set(element, { y: '100%', opacity: 0, scale: 1, duration: 0 });
        gsap.to(element, {
          y: '0',
          opacity: 1,
          scale: 1,
          ease: 'power3.inOut',
          duration: (FRAME_TRANSITION / 1000) * 0.6,
        });
      } else {
        // Exit state
        gsap.to(element, {
          y: '-100%',
          opacity: 0,
          scale: 1,
          ease: 'power3.inOut',
          duration: (FRAME_TRANSITION / 1000) * 0.6,
        });
      }
    });

    imagesRef.current.forEach((element, index) => {
      gsap.killTweensOf(element);

      const key = animationMedia[index].title.toLowerCase();
      if (activeFrame === key) {
        // Active state
        gsap.to(element, {
          y: '50%',
          opacity: 0,
          scale: 1,
          zIndex: 2,
          duration: 0,
        });
        gsap.to(element, {
          y: '0%',
          opacity: 1,
          scale: 1,
          ease: easing,
          duration: (FRAME_INTERVAL / 1000) * 1,
          delay: 0.001,
        });
      } else {
        // Exit state
        gsap.set(element, { zIndex: 1, duration: 0 });
        gsap.to(element, {
          y: '-30%',
          opacity: 0,
          scale: 1.0,
          ease: 'power3.out',
          duration: (FRAME_TRANSITION / 1000) * 0.5,
        });
      }
    });

    graphsRef.current.forEach((element, index) => {
      const key = animationMedia[index].title.toLowerCase();
      if (activeFrame === key) {
        // Active state
        gsap.set(element, {
          y: '50%',
          opacity: 0,
          zIndex: 2,
          duration: 0,
        });
        gsap.to(element, {
          y: '0%',
          opacity: 1,
          ease: easing,
          duration: (FRAME_INTERVAL / 1000) * 1,
        });
      } else {
        // Exit state
        gsap.set(element, { zIndex: 1, duration: 0 });
        gsap.to(element, {
          opacity: 0,
          ease: 'power3.out',
          duration: (FRAME_TRANSITION / 1000) * 0.5,
        });
      }
    });

    if (graphsContainerRef.current) {
      gsap.to(graphsContainerRef.current, {
        x: 0 - Math.random() * 40 + '%',
        y: 50 - Math.random() * 50 + '%',
        scale: 1.0 - Math.random() * 0.05,
        duration: (FRAME_INTERVAL / 1000) * 1,
      });
    }

    if (audioContainerRef.current) {
      gsap.to(audioContainerRef.current, {
        x: 50 - Math.random() * 50 + '%',
        y: 50 - Math.random() * 100 + '%',
        duration: (FRAME_INTERVAL / 1000) * 1,
      });
    }

    if (codeSnippetContainerRef.current) {
      gsap.to(codeSnippetContainerRef.current, {
        x: 50 + Math.random() * 10 + '%',
        y: Math.random() * 50 + '%',
        duration: (FRAME_INTERVAL / 1000) * 1,
      });
    }

    codeSnippetRef.current.forEach((element, index) => {
      const key = animationMedia[index].title.toLowerCase();
      if (activeFrame === key) {
        // Active state
        gsap.to(element, {
          y: '50%',
          opacity: 0,
          scale: 1,
          zIndex: 2,
          duration: 0,
        });
        gsap.to(element, {
          y: '0%',
          opacity: 1,
          scale: 1,
          ease: easing,
          duration: (FRAME_INTERVAL / 1000) * 1,
          delay: 0.001,
        });
      } else {
        gsap.to(element, {
          y: '-30%',
          opacity: 0,
          scale: 1.0,
          duration: (FRAME_TRANSITION / 1000) * 0.5,
        });
      }
    });
  };

  const [_, nextFrame] = useCycle({
    items: titleData.animation.map((item) => item.title.toLowerCase()),
    onUpdate: (val) => {
      animationTick(val);
    },
  });

  const intervalId = useRef(0);
  const rafId = useRef(0);
  const windowFocus = useRef(true);

  useEffect(() => {
    const startLoop = () => {
      intervalId.current = setInterval(() => {
        cancelAnimationFrame(rafId.current);
        rafId.current = requestAnimationFrame(() => {
          nextFrame();
        });
      }, FRAME_INTERVAL);
    };

    const stopLoop = () => {
      clearInterval(intervalId.current);
      cancelAnimationFrame(rafId.current);
    };

    const focusHandler = () => {
      if (windowFocus.current === false) {
        windowFocus.current = true;
        startLoop();
      }
    };

    const blurHandler = () => {
      if (windowFocus.current === true) {
        windowFocus.current = false;
        stopLoop();
      }
    };

    startLoop();

    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', blurHandler);

    return () => {
      cancelAnimationFrame(rafId.current);
      clearInterval(intervalId.current);
      window.removeEventListener('focus', focusHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }, []);

  useLayoutEffect(() => {
    // set initial frame
    const firstFrame = titleData.animation[0].title.toLowerCase();
    animationTick(firstFrame);
  }, []);

  return (
    <Wrapper>
      <Particles
        gap={3}
        y={22}
        z={27.3}
        xTarget={-7.24}
        yTarget={9}
        scale={10}
        zDepth={25}
      />
      <MainLayout>
        <GridContainer>
          <Content>
            <HeroTitle as="h1" color={colors.humeBlack700}>
              <Row>
                <Span>{titleData.part1}</Span>{' '}
                <AnimatedSpan>
                  <Placeholder>
                    {titleData.animation[0].title.toLowerCase()}
                  </Placeholder>
                  <AnimatedSpanWrapper>
                    {titleData.animation.map((item, i) => (
                      <Span
                        key={`word-${i}`}
                        aria-hidden="true"
                        ref={(el) => {
                          wordsRef.current[i] = el;
                        }}
                      >
                        {item.title === item.title.toUpperCase()
                          ? item.title
                          : item.title.toLowerCase()}
                      </Span>
                    ))}{' '}
                  </AnimatedSpanWrapper>
                </AnimatedSpan>
              </Row>
              <Row>
                <Span>{titleData.part2}</Span>
              </Row>
            </HeroTitle>
            <TextBodyLarge color={colors.humeBlack600}>
              {subtitle}
            </TextBodyLarge>
            {buttons && buttons.length && (
              <ButtonsContainer>
                {buttons.map((button, i) => (
                  <ContentfulButton data={button} key={`button-${i}`} />
                ))}
              </ButtonsContainer>
            )}
          </Content>
          <AnimationMain>
            <AnimationContainer>
              <ImagesContainer>
                {titleData.animation.map((image, i) => {
                  return (
                    <HomeHeroVideo
                      key={`video-${i}`}
                      video={image.media[4].file.url}
                      image={image.media[0].fixed.src}
                      ref={(el) => {
                        imagesRef.current[i] = el;
                      }}
                    />
                  );
                })}
              </ImagesContainer>
              <GraphsContainer ref={graphsContainerRef}>
                {titleData.animation.map((image, i) => {
                  return (
                    <GraphWrapper
                      key={`graph-${i}`}
                      ref={(el) => {
                        graphsRef.current[i] = el;
                      }}
                    >
                      <Img src={image.media[1].fixed.src} />
                    </GraphWrapper>
                  );
                })}
              </GraphsContainer>
              <CodeContainer ref={codeSnippetContainerRef}>
                {titleData.animation.map((image, i) => {
                  return (
                    <CodeWrapper
                      key={`code-${i}`}
                      ref={(el) => {
                        codeSnippetRef.current[i] = el;
                      }}
                    >
                      <Img src={image.media[2].fixed.src} />
                    </CodeWrapper>
                  );
                })}
              </CodeContainer>
              {/* <AudioContainer ref={audioContainerRef}>
                {titleData.animation.map((image, i) => {
                  return (
                    <AudioWrapper
                      key={`audio-${i}`}
                      ref={(el) => {
                        audioRef.current[i] = el;
                      }}
                    >
                      <Img src={image.media[3].fixed.src} />
                    </AudioWrapper>
                  );
                })}
              </AudioContainer> */}
            </AnimationContainer>
          </AnimationMain>
        </GridContainer>
      </MainLayout>
    </Wrapper>
  );
};
HomeHero.propTypes = HomeHeroProps;

export default HomeHero;

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  padding: 10rem 0;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  align-items: center;
  gap: 4rem;
  height: 100%;

  ${mq.mobileL} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(11, 1fr);
    gap: 0;
  }

  ${mq.desk} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  z-index: 3;

  ${mq.mobileL} {
    grid-area: 1 / 1 / 6 / 9;
    gap: 2.4rem;
  }

  ${mq.tabletP} {
    grid-area: 1 / 1 / 6 / 8;
  }

  ${mq.desk} {
    grid-area: auto;
  }
`;

export const Row = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  ${mq.mobileL} {
    flex-direction: row;
    gap: 1.8rem;
  }
`;

export const Span = styled.span``;

export const AnimatedSpan = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const AnimatedSpanWrapper = styled.div`
  position: absolute;
  inset: 0;
  /* overflow: hidden; */

  & > span {
    position: absolute;
    inset: 0;
    opacity: 0;

    &:nth-of-type(1) {
      color: ${colors.rajah};
    }
    &:nth-of-type(2) {
      color: ${colors.tulip};
    }
    &:nth-of-type(3) {
      color: ${colors.lavenderMagenta};
    }
    &:nth-of-type(4) {
      color: ${colors.aero};
    }
    &:nth-of-type(5) {
      color: ${colors.mediumSkyBlue};
    }
    &:nth-of-type(6) {
      color: ${colors.pastelGreen};
    }
    &:nth-of-type(7) {
      color: ${colors.roses};
    }
    &:nth-of-type(8) {
      color: ${colors.lightBlue};
    }
    &:nth-of-type(9) {
      color: ${colors.lavender};
    }
  }
`;

export const Placeholder = styled(Span)`
  opacity: 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  margin-top: 2.4rem;

  ${mq.tabletP} {
    flex-wrap: nowrap;
    gap: 2.4rem;
  }
`;

export const AnimationMain = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${mq.mobileL} {
    grid-area: 6 / 6 / 12 / 12;
  }

  ${mq.tabletP} {
    grid-area: 5 / 7 / 12 / 12;
  }

  ${mq.desk} {
    grid-area: auto;
  }
`;

export const AnimationContainer = styled.div`
  position: relative;
  max-height: 40rem;
  height: 100%;
  width: 100%;

  ${mq.tabletP} {
    max-width: 48rem;
    max-height: 53.3rem;
  }
`;

export const ImagesContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 0.8rem;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 0.8rem;
`;

export const GraphsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 24.5rem;
  height: 15.6rem;
  background-color: ${colors.neutralWhite};
  border-radius: 0.8rem;
  border: 0.05rem solid ${colors.humeTan500};
  overflow: hidden;
  z-index: 3;

  ${mq.tabletP} {
    max-width: 35rem;
    height: 23rem;
  }
`;

export const GraphWrapper = styled.div`
  position: absolute;
  inset: 0;
`;

export const CodeContainer = styled(GraphsContainer)`
  top: 0;
  right: 0;
  left: unset;
  bottom: unset;
  max-width: 19.8rem;
  height: 9.98rem;
  background-color: ${colors.humeBlack700};

  ${mq.tabletP} {
    max-width: 28.8rem;
    height: 14.5rem;
  }
`;

export const CodeWrapper = styled.div`
  position: absolute;
  inset: 0;
`;

export const AudioContainer = styled(GraphsContainer)`
  top: unset;
  left: unset;
  bottom: 0;
  right: 0;
  max-width: 9.23rem;
  height: 2.1rem;
  background-color: ${colors.neutralWhite};

  ${mq.tabletP} {
    max-width: 14.7rem;
    height: 5.2rem;
  }
`;

export const AudioWrapper = styled.div`
  position: absolute;
  inset: 0;
`;
