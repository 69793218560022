import React from 'react';
import PropTypes from 'prop-types';
import HomeRow from 'components/HomeRow';
import { Heading2, TextBodyLarge, TextCTA } from 'components/TextStyles';
import Spacer from 'components/Spacer';
import Button from 'components/Button';
import PageTransitionLink from 'components/PageTransitionLink';
import IconArrowheadRight from 'components/_svgs/IconArrowheadRight';
import { buttonVariants, buttonThemes } from 'components/Button/index.style';
import AnimateSplitText from 'components/_animation/AnimateSplitText';
import AnimateFadeIn from 'components/_animation/AnimateFadeIn';
import { colors } from 'styles/vars/colors.style';

const HomeScience = ({ carouselItems, kickerText, title, content, button }) => {
  return (
    <HomeRow carouselItems={carouselItems}>
      <Spacer size={[5, 100]} />

      <TextCTA color={colors.humeTan700}>
        <AnimateSplitText delay={0}>{kickerText}</AnimateSplitText>
      </TextCTA>

      <Spacer size={[5, 10]} />

      <Heading2 as="h3">
        <AnimateSplitText delay={0.2} type={`lines`} heading={true}>
          {title}
        </AnimateSplitText>
      </Heading2>

      <Spacer size={[18, 24]} />

      {content && (
        <>
          <TextBodyLarge>
            <AnimateSplitText delay={0.4}>{content}</AnimateSplitText>
          </TextBodyLarge>
        </>
      )}

      <Spacer size={[24, 30]} />

      <AnimateFadeIn delay={0.6}>
        <PageTransitionLink to={button.url} scrollSection={'papers'}>
          <Button
            as="span"
            variant={buttonVariants[button.variant]}
            theme={buttonThemes[button.theme]}
            newTab={button.newTab}
            iconRight={<IconArrowheadRight />}
          >
            {button.text}
          </Button>
        </PageTransitionLink>
      </AnimateFadeIn>
    </HomeRow>
  );
};

export default HomeScience;

HomeScience.propTypes = {
  carouselItems: PropTypes.arrayOf(PropTypes.object),
  kickerText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  button: PropTypes.object.isRequired,
};
