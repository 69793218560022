import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import { ScreenContext } from 'components/Providers/Screen';

import Container from '../_layouts/Container';
import { Heading2, TextBodyLarge, Heading6 } from '../TextStyles';
import ContentfulButton from 'components/ContentfulButton';
import VideoPlayer from 'components/VideoPlayer';
import Spacer from '../Spacer';
import { useMedia } from 'hooks/useMedia';

//For build ons
import AnimateSplitText from '../_animation/AnimateSplitText';
import AnimateFadeIn from '../_animation/AnimateFadeIn';

import {
  HomeHeroWrapper,
  HomeHeroMain,
  HomeHeroContent,
  HomeHeroButtons,
} from './index.style';
import { shuffle } from '../../utils/utils';
import { useCallback } from 'react';
import Particles from '../Particles';

const HomeHeroTwo = ({ title, text, images, buttons, video, poster }) => {
  const buttonIcon = useMedia(true, true, true, true, true, true, false, false);
  const animationLoopIndex = useRef(0);
  const imageRefs = useRef([]);
  const imageLength = 7;
  const overlayRefs = useRef([]);
  const contentRef = useRef();
  const debug = false;

  const screen = useContext(ScreenContext);

  const getShuffledImages = useCallback(() => {
    const imagesCopy = [...images];
    let shuffledImages = [];

    for (let i = 0; i < imageLength; i++) {
      const imgIndex = gsap.utils.random(0, imagesCopy.length - 1, 1);
      shuffledImages.push(imagesCopy[imgIndex]);
      imagesCopy.splice(imgIndex, 1);
    }

    return shuffledImages;
  }, [images]);

  const [imageArray, setImageArray] = useState(getShuffledImages());

  const updateImagePositions = useCallback(() => {
    imageRefs.current.forEach((image, i) => {
      const scale = gsap.utils.random(0.3, 1, 0.1);
      const offsetX = window.innerWidth * 0.02;
      const scaledOffsetX = (1 / scale) * offsetX + offsetX * (1 - scale);
      const offsetY = window.innerHeight * 0.02;
      const scaledOffsetY = (1 / scale) * offsetY + offsetY * (1 - scale);

      gsap.set(image, {
        x: gsap.utils.random(-scaledOffsetX, scaledOffsetX, imageLength),
        y: gsap.utils.random(-scaledOffsetY, scaledOffsetY, imageLength),
        scale: scale,
      });

      gsap.set(overlayRefs.current[i], {
        opacity: 1 - scale,
      });
    });
  }, []);

  useEffect(() => {
    let tl;

    const animateImages = () => {
      const yOffset = 80;
      const rotationX = 50;
      tl = gsap.timeline({
        onComplete: () => {
          const shuffledImages = getShuffledImages();
          setImageArray(shuffledImages);
        },
      });

      animationLoopIndex.current = 1;
      updateImagePositions();

      tl.fromTo(
        shuffle(imageRefs.current),
        {
          y: `+=${yOffset}`,
          opacity: 0,
          rotationX: -rotationX,
        },
        {
          y: `-=${yOffset}`,
          opacity: 1,
          rotationX: 0,
          duration: 1.2,
          stagger: 0.3,
          ease: 'power3.out',
        },
      ).to(shuffle(imageRefs.current), {
        y: `+=${yOffset}`,
        rotationX: -rotationX,
        opacity: 0,
        duration: 0.8,
        stagger: 0.3,
        delay: 2,
        ease: 'power3.in',
      });
    };

    if (!debug) {
      if (animationLoopIndex.current === 0) {
        setTimeout(() => {
          // animateImages()
        }, 2000);
      } else {
        animateImages();
      }
    }

    return () => {
      if (tl) {
        tl.kill();
      }
    };
  }, [imageArray, images, updateImagePositions, debug, getShuffledImages]);

  const buttonEls = buttons.map((item, index) => {
    const delay = index * 0.2 + 1.6;

    return (
      <AnimateFadeIn delay={delay} key={index}>
        <ContentfulButton data={item} noArrow={!buttonIcon} />
      </AnimateFadeIn>
    );
  });

  return (
    <HomeHeroWrapper>
      {(screen.desk || screen.deskL || screen.contained) && (
        <Particles images={images} />
      )}
      {screen.tabletL && <Particles images={images} y={2} yTarget={1} />}
      {screen.tabletP && <Particles images={images} y={3} yTarget={0} />}
      {screen.mobileL && <Particles images={images} y={3} yTarget={1} />}
      {screen.mobile && <Particles images={images} y={5} yTarget={2} />}
      {screen.tiny && <Particles images={images} y={3} yTarget={3} />}
      <HomeHeroMain>
        <Container>
          <HomeHeroContent ref={contentRef}>
            <Heading2 as="h1">
              <AnimateSplitText delay={1} type={`lines`} heading={true}>
                {title}
              </AnimateSplitText>
            </Heading2>

            <Spacer size={[18, 24]} />

            <TextBodyLarge>
              <AnimateSplitText delay={1.2}>{text}</AnimateSplitText>
            </TextBodyLarge>

            <Spacer size={[24, 38]} />

            <HomeHeroButtons>{buttonEls}</HomeHeroButtons>
            {(screen.tiny || screen.mobile || screen.mobileL) && (
              <Heading6>
                <AnimateSplitText delay={1.4}>
                  Watch how it works
                </AnimateSplitText>
              </Heading6>
            )}
            <VideoPlayer src={video} poster={poster} />
          </HomeHeroContent>
        </Container>
      </HomeHeroMain>
    </HomeHeroWrapper>
  );
};

HomeHeroTwo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  images: PropTypes.array,
};

export default HomeHeroTwo;
