import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Displays a preview image until video loads

const HomeHeroVideo = forwardRef(({ video, image }, ref) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <VideoWrapper ref={ref}>
      {!videoLoaded && <Img src={image} />}
      <Video
        autoPlay
        muted
        loop
        src={video}
        onLoadedData={() => setVideoLoaded(true)}
      />
    </VideoWrapper>
  );
});
HomeHeroVideo.displayName = 'HomeHeroVideo'

export default HomeHeroVideo;

HomeHeroVideo.propTypes = {
  video: PropTypes.string,
  image: PropTypes.string,
};

export const VideoWrapper = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 0.8rem;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 0.8rem;
`;

export const Img = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 0.8rem;
`;
