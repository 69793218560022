import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide-core.min.css';
import PostPreview from 'components/PostPreview';
import ContentfulButton from 'components/ContentfulButton';
import Button from '../Button';
import {
  buttonVariants,
  ButtonIconWrapper,
} from 'components/Button/index.style';
import IconArrowHeadRight from 'components/_svgs/IconArrowheadRight';
import AnimateSplitText from 'components/_animation/AnimateSplitText';
import { Heading3, TextCTA, TextBodyLarge } from 'components/TextStyles';
import { mq } from 'styles/vars/media-queries.style';
import { clamp } from 'styles/utils/conversion.style';
import { colors } from 'styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { useMedia } from 'hooks/useMedia';
import SectionWrapper from '../_layouts/SectionWrapper';

const LatestBlogPosts = ({ title, kickerText, description, posts, button }) => {
  const [translateIndex, setTranslateIndex] = useState(0);

  const onlyMobileView = useMedia(
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
  );

  const SplideOptions = {
    type: 'slide',
    perPage: onlyMobileView ? 1 : 3,
    gap: '4vw',
    speed: 800,
    easing: easings.inOut.cubic,
    pagination: false,
  };

  const decreaseIndex = () => {
    if (translateIndex === 0) {
      return;
    } else {
      setTranslateIndex(translateIndex - 1);
    }
  };

  const increaseIndex = () => {
    if (translateIndex >= posts.length / SplideOptions.perPage - 1) {
      return;
    } else {
      setTranslateIndex(translateIndex + 1);
    }
  };

  return (
    <SectionWrapper>
      <Splide options={SplideOptions} hasTrack={false}>
        <Header>
          <Title>
            <TextCTA color={colors.humeTan700}>{kickerText}</TextCTA>
            <Heading3 as="h3" color={colors.humeBlack700}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading3>
            <TextBodyLarge color={colors.humeTan700}>
              {description}
            </TextBodyLarge>
          </Title>
          <ArrowButtons className="splide__arrows">
            <ArrowButtonWrapper
              className="splide__arrow splide__arrow--prev"
              disabled={translateIndex === 0}
              $rotate
            >
              <Button
                variant={buttonVariants.plain}
                iconRight={<IconArrowHeadRight />}
                ariaLabel={`Previous`}
                disabled={translateIndex === 0}
                onClick={decreaseIndex}
              ></Button>
            </ArrowButtonWrapper>
            <ArrowButtonWrapper
              className="splide__arrow splide__arrow--next"
              disabled={
                translateIndex >= posts.length / SplideOptions.perPage - 1
              }
            >
              <Button
                variant={buttonVariants.plain}
                iconRight={<IconArrowHeadRight />}
                ariaLabel={`Previous`}
                onClick={increaseIndex}
                disabled={
                  translateIndex >= posts.length / SplideOptions.perPage - 1
                }
              ></Button>
            </ArrowButtonWrapper>
          </ArrowButtons>
        </Header>
        <PostsWrapper>
          <SplideTrack>
            {posts.map((post, i) => (
              <SplideSlide key={`post-${i}`}>
                <PostPreview {...post} isMobile={onlyMobileView} />
              </SplideSlide>
            ))}
          </SplideTrack>
        </PostsWrapper>

        {onlyMobileView && (
          <ButtonWrapper>
            <ContentfulButton data={button} />
          </ButtonWrapper>
        )}
      </Splide>
    </SectionWrapper>
  );
};

export default LatestBlogPosts;

LatestBlogPosts.propTypes = {
  title: PropTypes.string.isRequired,
  kickerText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.object),
  button: PropTypes.object.isRequired,
};

export const Main = styled.div`
  padding: 6rem 0;

  ${mq.tabletP} {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
    ${clamp('padding-left', 65, 125)}
    ${clamp('padding-right', 65, 125)}
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;

  ${mq.deskL} {
    max-width: 160rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  gap: 1.2rem;
  margin-bottom: 4rem;

  ${mq.tiny} {
    flex-direction: column;
  }

  ${mq.mobile} {
    flex-direction: column;
  }

  ${mq.tabletP} {
    padding: 0;
    flex-direction: row;
  }

  ${mq.desk} {
    flex-direction: row;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 0.6rem;
`;

export const PostsWrapper = styled.div`
  ${mq.tabletP} {
    padding: 0;
  }

  ${mq.desk} {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
`;

const ArrowButtons = styled.div.attrs({
  className: 'splide__arrows',
})`
  display: flex;
  align-items: center;
  margin-bottom: unset;

  gap: 20px;
`;

const ArrowButtonWrapper = styled.div`
  text-align: right;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transform: ${(props) => (props.$rotate ? 'scaleX(-1)' : 'unset')};
  transition: opacity 0.3s ease;

  ${mq.desk} {
    display: block;
  }

  button {
    border-radius: 50%;
    border: 0.1rem solid ${colors.humeBlack700};
    height: 5.6rem;
    min-width: 5.6rem;
    padding: 0;
    width: 5.6rem;
    margin-bottom: unset;

    span {
      font-size: 0;
      margin-left: 0;
      padding: 0;
    }
  }

  ${ButtonIconWrapper} {
    margin-top: 0;
  }
`;
