import React from 'react';
import styled from 'styled-components';
import { clamp } from 'styles/utils/conversion.style';

const MainLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default MainLayout;

const Wrapper = styled.div`
  ${clamp('padding-left', 22, 50)};
  ${clamp('padding-right', 22, 50)};
  max-width: 160rem;
  margin: 0 auto;
`;
