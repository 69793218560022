import styled from 'styled-components';
import { mq } from '../../styles/vars/media-queries.style';

export const HomeRowMain = styled.div``;

export const HomeRowTextWrapper = styled.div`
  ${mq.tabletL} {
    a {
      margin-bottom: 14rem;
    }
  }

  ${mq.desk} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
