import { useState, useRef, useCallback } from 'react';

/**
 * @type {<T>(options: {items: T[], onUpdate?: () => void}) => [T, () => void]}
 */
export const useCycle = ({ items, onUpdate }) => {
  const [value, setValue] = useState(items[0]);
  const index = useRef(0);

  const next = useCallback(() => {
    index.current += 1;

    const val = items[index.current % items.length];

    setValue(val);
    if (onUpdate) {
      onUpdate(val);
    }
  }, [items, onUpdate]);

  return [value, next];
};
