import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import BlogTag from 'components/BlogTag';
import Button from 'components/Button';
import IconArrowheadRight from 'components/_svgs/IconArrowheadRight';
import PageTransitionLink from 'components/PageTransitionLink';
import RichText from 'components/RichText';
import { buttonVariants } from 'components/Button/index.style';
import { Heading5 } from 'components/TextStyles';
import { TextStyle } from 'styles/vars/textStyles.style';
import Routes from '../../routes';

const PlaceholderImage = ({ title }) => {
  const image = useStaticQuery(graphql`
    query placeholderQuery {
      allContentfulAsset(
        filter: { contentful_id: { eq: "5cxAnswHjl0vwQTJPsNzQy" }, file: {} }
      ) {
        nodes {
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `).allContentfulAsset.nodes[0];

  return <Img fluid={image.fluid} alt={title} />;
};

const PostPreview = ({
  typeOfPost,
  slug,
  heroImage,
  heroTitle,
  heroCopy,
  isMobile = false,
}) => {
  const buttonActionText = (type) => {
    switch (type) {
      case 'Podcast':
        return 'Listen to Podcast';
      case 'Product Updates':
      case 'Science':
      default:
        return 'Read More';
    }
  };

  if (isMobile) {
    return (
      <Wrapper>
        <BlogTag type={typeOfPost} />
        <Heading5 as="h5">
          <PageTransitionLink
            to={`${Routes.hub}/${slug}/`}
            aria-label={buttonActionText(typeOfPost)}
          >
            {heroTitle}
          </PageTransitionLink>
        </Heading5>
        {heroCopy && (
          <Content>
            <RichText content={heroCopy} delay={0.6} />
          </Content>
        )}
        <ButtonWrapper>
          <Button
            as={`a`}
            variant={buttonVariants.plain}
            href={`${Routes.hub}/${slug}/`}
            iconRight={<IconArrowheadRight />}
          >
            {buttonActionText(typeOfPost)}
          </Button>
        </ButtonWrapper>
        <Image>
          <PageTransitionLink
            to={`${Routes.hub}/${slug}/`}
            aria-label={buttonActionText(typeOfPost)}
          >
            {heroImage ? (
              <Img fluid={heroImage.fluid} alt={heroImage.title} />
            ) : (
              <PlaceholderImage title={heroTitle} />
            )}
          </PageTransitionLink>
        </Image>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Image>
        <PageTransitionLink
          to={`${Routes.hub}/${slug}/`}
          aria-label={buttonActionText(typeOfPost)}
        >
          {heroImage ? (
            <Img fluid={heroImage.fluid} alt={heroImage.title} />
          ) : (
            <PlaceholderImage title={heroTitle} />
          )}
        </PageTransitionLink>
      </Image>
      <BlogTag type={typeOfPost} />
      <Heading5 as="h5">
        <PageTransitionLink
          to={`${Routes.hub}/${slug}/`}
          aria-label={buttonActionText(typeOfPost)}
        >
          {heroTitle}
        </PageTransitionLink>
      </Heading5>
      {heroCopy && (
        <Content>
          <RichText content={heroCopy} delay={0.6} />
        </Content>
      )}
      <ButtonWrapper>
        <Button
          as={`a`}
          variant={buttonVariants.plain}
          href={`${Routes.hub}/${slug}/`}
          iconRight={<IconArrowheadRight />}
        >
          {buttonActionText(typeOfPost)}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default PostPreview;

PostPreview.propTypes = {
  typeOfPost: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fluid: PropTypes.object.isRequired,
  }),
  heroTitle: PropTypes.string.isRequired,
  heroCopy: PropTypes.shape({
    raw: PropTypes.string.isRequired,
  }),
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 3rem;
`;

export const Image = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  a {
    display: block;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  * {
    ${TextStyle.TextBodySmall}
    font-style: normal;
  }
`;

export const ButtonWrapper = styled.div``;
