import styled from 'styled-components';
import { mq } from '../../styles/vars/media-queries.style';
import { ContainerMain } from '../_layouts/Container/index.style';

export const HomeHeroWrapper = styled.div`
  overflow: hidden;
  position: relative;

  ${mq.tiny} {
    height: 1280px;
  }

  ${mq.mobile} {
    height: 1350px;
  }

  ${mq.tabletP} {
    height: 1350px;
  }

  ${mq.tabletL} {
    height: 1500px;
  }

  ${mq.desk} {
    min-height: 100vh;
    height: 900px;
  }

  ${mq.deskL} {
    height: 50vw;
  }

  ${mq.contained} {
    height: 43vw;
  }
`;

export const HomeHeroMain = styled.div`
  height: 100%;

  ${ContainerMain} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    height: 100%;
    min-height: 45rem;

    ${mq.contained} {
      padding-top: 15vh;
    }
  }
`;

export const HomeHeroContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  p {
    margin: 0 auto;
    z-index: 2;
  }

  h1 {
    max-width: 16em;
  }

  p {
    max-width: 34em;
  }
  /* 
  margin-bottom: 480px;
  
  ${mq.mobile} {
    margin-bottom: 460px;
  }

  ${mq.desk} {
    margin-bottom: 75px;
  } */
`;

export const HomeHeroButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mq.tiny} {
    gap: 20px;
    margin-bottom: 400px;
  }

  ${mq.mobile} {
    flex-direction: row;
    margin-bottom: 520px;
  }

  ${mq.mobileL} {
    gap: 30px;
    margin-bottom: 380px;
  }

  ${mq.tabletP} {
    margin-bottom: 380px;
  }

  ${mq.desk} {
    margin-bottom: 30px;
  }
`;

export const ParticlesWrapper = styled.div``;
