import styled, { keyframes, css } from 'styled-components';
import { clamp } from 'styles/utils/conversion.style';
import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';
import { TextStyle } from 'styles/vars/textStyles.style';

export const TickerMain = styled.div`
  padding: 4.3rem 0;
  text-align: center;
  ${({ $borderTop }) =>
    $borderTop &&
    css`
      border-top: 0.1rem solid ${colors.humeBlack700};
    `}
  ${({ $borderBottom }) =>
    $borderBottom &&
    css`
      border-bottom: 0.1rem solid ${colors.humeBlack700};
    `}
`;

export const Title = styled.p`
  ${TextStyle.TextBodyLarge}
  margin-bottom: 4.3rem;
`;

export const TickerOuter = styled.div`
  font-size: 0;
  white-space: nowrap;
  overflow: hidden;
  will-change: transform;
`;

export const TickerInner = styled.div`
  display: inline-block;
  will-change: transform;
`;

const ticker = keyframes`
  from {
    transform: translate3d(0%, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
`;

export const TickerContent = styled.div`
  display: inline-block;
  white-space: nowrap;
  will-change: transform;

  [data-inview='true'] & {
    animation: ${ticker} 40s linear -1s infinite forwards;
  }

  a {
    display: inline-flex;
    ${clamp('padding-left', 24, 53)}
    ${clamp('padding-right', 24, 53)}
    vertical-align: middle;
    border: none;
  }

  img {
    display: block;
    ${clamp('margin-left', 48, 106)}
    ${clamp('margin-right', 48, 106)}
    width: 100%;

    ${mq.desk} {
      ${clamp('margin-left', 24, 53)}
      ${clamp('margin-right', 24, 53)}
    }
  }
`;
