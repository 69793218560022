import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  Mask,
  Wrapper,
  Video,
  PlayPauseButton,
  Pill,
  Maximize,
  Minimize,
  Unmute,
  Mute,
  VR,
  X,
  Controls,
  ControlButton,
  CloseButton,
} from './index.styles';
import { ScreenContext } from 'components/Providers';
import gsap from 'gsap';
import { colors } from 'styles/vars/colors.style';
import { getMedia } from '../../utils/utils';

const VideoPlayer = ({ src, poster }) => {
  const pillEl = useRef(null);
  const videoEl = useRef(null);
  const [maskEl, setMaskEl] = useState(null);
  const [wrapperEl, setWrapperEl] = useState(null);
  const [controlsEl, setControlsEl] = useState(null);
  const [playButton, setPlayButton] = useState(null);
  const [closeButton, setCloseButton] = useState(null);
  const [paused, setPaused] = useState(true);
  const [muted, setMuted] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const mouseInVideo = useRef(false);
  const mouseMoveTimeout = useRef(null);
  const firstPlay = useRef(true);
  const screen = useContext(ScreenContext);

  const handleOrientation = useCallback(
    (e) => {
      if (maskEl && fullScreen) {
        setTimeout(() => {
          gsap.set(maskEl, {
            overwrite: true,
            height: window.innerHeight,
            width: window.innerWidth,
          });
        }, 250);
      }
    },
    [maskEl, fullScreen],
  );

  const mouseMove = useCallback(() => {
    if (mouseInVideo.current) {
      clearTimeout(mouseMoveTimeout.current);

      if (!firstPlay.current) {
        if (!fullScreen) {
          gsap.to([pillEl.current], {
            opacity: 1,
          });
        }
        gsap.to([controlsEl, playButton, closeButton], {
          opacity: 1,
        });
      }

      mouseMoveTimeout.current = setTimeout(() => {
        if (!firstPlay.current) {
          if (!fullScreen) {
            gsap.to([pillEl.current], {
              opacity: 0,
              duration: 0.75,
            });
          }
          gsap.to([controlsEl, playButton, closeButton], {
            opacity: 0,
            duration: 0.75,
          });
        }
      }, 250);
    }
  }, [controlsEl, fullScreen, playButton, closeButton, paused]);

  const minimize = useCallback(() => {
    if (maskEl && closeButton && playButton && controlsEl) {
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set(['.video-fill'], {
            fill: colors.humeTan400,
          });

          window.scroll.start();
          gsap.set('.body', {
            overflow: 'unset',
          });
        },
      });

      tl.set(maskEl, {
        position: 'absolute',
      });

      tl.to(
        maskEl,
        {
          bottom: () =>
            getMedia('10vh', '8vh', 40, '5vh', '10vh', 100, 60, '10vh'),
          height: () =>
            getMedia('18vw', 350, 350, 420.75, 330, 330, '50.63vw', '50.63vw'),
          width: () =>
            getMedia(
              '32vw',
              622.22,
              622.22,
              748,
              586.67,
              586.67,
              '90vw',
              '90vw',
            ),
        },
        0,
      );

      tl.to(
        playButton,
        {
          bottom: '45%',
          background: colors.humeBlack700,
          width: 60,
          height: 60,
        },
        0,
      );

      tl.fromTo(
        '.video-fill',
        {
          fill: colors.humeBlack700,
        },
        {
          fill: colors.humeTan400,
          overwrite: true,
        },
        0,
      );

      tl.to(
        closeButton,
        {
          top: '-50%',
        },
        0,
      );

      tl.to(
        controlsEl,
        {
          bottom: '-50%',
        },
        0,
      );

      if (screen.tiny || screen.mobile || screen.mobileL) {
        tl.to(
          '#header',
          {
            opacity: 1,
          },
          0,
        );
      }
    }
  }, [maskEl, closeButton, controlsEl, screen, playButton]);

  const maximize = useCallback(() => {
    if (maskEl && closeButton && playButton && controlsEl) {
      const { top, height } = maskEl.getBoundingClientRect();
      const { y: scrollY } = window.scroll.scroll.instance.scroll;

      console.log(top, scrollY, height);

      const tl = gsap.timeline({
        onStart: () => {
          if (screen.desk || screen.deskL || screen.contained) {
            window.scroll.scrollTo(top - window.innerHeight / 2 + scrollY);
          }
          // if (screen.tabletL) {
          //   window.scroll.scrollTo(1500 - window.innerHeight);
          // }
          // if (screen.tabletP) {
          //   window.scroll.scrollTo(1350 - window.innerHeight);
          // }
          window.scroll.stop();
          gsap.set('.body', {
            overflow: 'hidden',
          });
        },
        onComplete: () => {
          if (!firstPlay.current) {
            gsap.set(pillEl.current, {
              display: 'flex',
            });
          }
        },
      });

      tl.set(
        maskEl,
        {
          position: 'fixed',
        },
        0,
      );

      tl.to(
        maskEl,
        {
          bottom: () =>
            getMedia(
              window.innerHeight > 900 ? 15 : 900 - window.innerHeight,
              window.innerHeight > 900 ? 15 : 900 - window.innerHeight,
              window.innerHeight > 900 ? 15 : 900 - window.innerHeight,
              0,
              0,
              0,
              0,
              0,
            ),
          height: () =>
            getMedia(
              '90vh',
              '88vh',
              '88vh',
              window.innerHeight,
              window.innerHeight,
              window.innerHeight,
              window.innerHeight,
              window.innerHeight,
            ),
          width: '100vw',
        },
        0,
      );

      tl.to(
        playButton,
        {
          bottom: 50,
          background: colors.neutralWhite,
          width: 75,
          height: 75,
        },
        0,
      );

      tl.fromTo(
        '.video-fill',
        {
          fill: colors.humeTan400,
        },
        {
          fill: colors.humeBlack700,
          overwrite: true,
        },
        0,
      );

      tl.to(
        closeButton,
        {
          top: 40,
        },
        0,
      );

      tl.to(
        controlsEl,
        {
          bottom: 61.5,
        },
        0,
      );

      if (screen.tiny || screen.mobile || screen.mobileL) {
        tl.to(
          '#header',
          {
            opacity: 0,
          },
          0,
        );
      }
    }
  }, [maskEl, closeButton, controlsEl, screen, playButton]);

  const close = () => {
    setFullScreen(false);
    setPaused(true);
    videoEl.current.load();
  };

  const mouseEnter = () => {
    mouseInVideo.current = true;
    if (!fullScreen) {
      gsap.to(playButton, {
        scale: 1.2,
      });
    }
  };

  const mouseLeave = () => {
    mouseInVideo.current = false;
    if (!fullScreen) {
      gsap.to(playButton, {
        scale: 1,
      });
    }
  };

  const buttonEnter = () => {
    if (fullScreen) {
      gsap.set([playButton, '.video-fill'], {
        background: colors.humeBlack700,
        fill: colors.humeTan400,
      });
    }
  };

  const buttonLeave = () => {
    if (fullScreen) {
      gsap.set([playButton, '.video-fill'], {
        background: colors.neutralWhite,
        fill: colors.humeBlack700,
      });
    }
  };

  useEffect(() => {
    if (videoEl.current && pillEl.current) {
      if (paused) {
        videoEl.current.pause();
      } else {
        videoEl.current.play();
        if (!fullScreen) {
          gsap.set(pillEl.current, {
            display: 'flex',
          });
        }
        if (firstPlay.current) {
          firstPlay.current = false;
        }
      }
    }
  }, [paused, videoEl, pillEl]);

  useEffect(() => {
    if (fullScreen) {
      maximize();
      gsap.to(pillEl.current, {
        opacity: 0,
      });
      gsap.to('.video-fill', {
        fill: colors.humeBlack700,
      });
    } else {
      minimize();
      gsap.to(pillEl.current, {
        opacity: 1,
      });
      gsap.to('.video-fill', {
        fill: colors.humeTan400,
      });
    }
  }, [fullScreen]);

  useEffect(() => {
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('touchstart', mouseMove);
    window.addEventListener('orientationchange', handleOrientation);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchstart', mouseMove);
      window.removeEventListener('orientationchange', handleOrientation);
    };
  }, [mouseMove, handleOrientation]);

  return (
    <Mask ref={(ref) => setMaskEl(ref)}>
      <Wrapper
        ref={(ref) => setWrapperEl(ref)}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <CloseButton ref={(ref) => setCloseButton(ref)} onClick={close}>
          <X />
        </CloseButton>
        <Video muted={muted} playsInline ref={videoEl} poster={poster}>
          <source src={src.file.url} type={src.file.contentType} />
        </Video>
        <PlayPauseButton
          ref={(ref) => setPlayButton(ref)}
          onClick={() => setPaused(!paused)}
          onMouseEnter={buttonEnter}
          onMouseLeave={buttonLeave}
        >
          {paused && (
            <svg
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="video-fill"
                d="M7.61523 20.5469L21.6152 11.5469L7.61523 2.54688V20.5469Z"
                fill="#FFF4E8"
              />
            </svg>
          )}
          {!paused && (
            <svg
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                className="video-fill"
                x="4.61523"
                y="1.5"
                width="3"
                height="16"
                fill="#FFF4E8"
              />
              <rect
                className="video-fill"
                x="11.6152"
                y="1.5"
                width="3"
                height="16"
                fill="#FFF4E8"
              />
            </svg>
          )}
        </PlayPauseButton>
        <Pill ref={pillEl}>
          {!muted && <Unmute onClick={() => setMuted(true)} />}
          {muted && <Mute onClick={() => setMuted(false)} />}
          <VR />
          <Maximize onClick={() => setFullScreen(true)} />
        </Pill>
        <Controls ref={(ref) => setControlsEl(ref)}>
          <ControlButton onClick={() => setMuted(!muted)}>
            {!muted && <Unmute />}
            {muted && <Mute />}
          </ControlButton>
          <ControlButton onClick={() => setFullScreen(false)}>
            <Minimize />
          </ControlButton>
        </Controls>
      </Wrapper>
    </Mask>
  );
};

export default VideoPlayer;
