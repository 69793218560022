import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import HomeCarousel from '../HomeCarousel';

import { HomeRowMain, HomeRowTextWrapper } from './index.style';
import Spacer from '../Spacer';

const HomeRow = ({ carouselItems, reverse, children }) => {
  const [autoplay, setAutoplay] = useState(false);

  return (
    <HomeRowMain onMouseEnter={() => setAutoplay(true)}>
      <Spacer size={[120, 240]} />
      <Container>
        <Grid>
          <GridItem
            tabletP={7}
            tabletPStart={reverse ? 10 : 1}
            tabletPOrder={reverse ? 2 : undefined}
            tabletL={6}
            tabletLStart={reverse ? 10 : 2}
            desk={5}
            deskStart={reverse ? 10 : 3}
            deskL={4}
            deskLStart={reverse ? 11 : 3}
          >
            <HomeRowTextWrapper>{children}</HomeRowTextWrapper>
          </GridItem>

          <GridItem
            tabletP={8}
            tabletPStart={reverse ? 1 : 9}
            tabletPOrder={reverse ? 1 : undefined}
            deskStart={reverse ? 1 : 9}
          >
            <HomeCarousel
              autoplay={autoplay}
              items={carouselItems}
              reverse={reverse}
            />
          </GridItem>
        </Grid>
      </Container>
    </HomeRowMain>
  );
};

HomeRow.propTypes = {
  children: PropTypes.node,
  reverse: PropTypes.bool,
  carouselItems: PropTypes.array,
};

export default HomeRow;
